import activity from './m-meet-the-team.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Meet The Team',
  icon: 'mdi-hand-wave',
  description: 'Introduce yourself',
  status: true,
  styling: {
    borderColor: '#0077B5'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-meet-the-team',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        videoaskLink: '',
        videoaskResponseLink: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
